
#user-view .widget-author {
    background-color: #ffffff;
    border-radius: 8px;
    margin-bottom: 20px;
}

#user-view .widget-author .author-name {
    line-height: 20px;
}

.user-list-view .row > [class^="col-"] {
    max-width: 100% !important;
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    -webkit-flex: 100%;
    flex: 100%;
}


.widget-user-about .user-info p {
    color: #9e9faf;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
}

.widget-user-about .info-list li {
    font-size: 14px;
    margin-bottom: 6px;
}

.widget-user-about .info-list li:last-child {
    margin-bottom: 0;
}

.widget-user-about .info-list li span {
    color: #9e9faf;
    min-width: 75px;
    display: inline-block;
}

.widget-user-about .info-list li a {
    color: #5edfff;
}

.widget-user-about .info-list .social-share {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.widget-user-about .info-list .social-share .social-icon a {
    color: #646464;
    font-size: 15px;
    padding: 0 5px;
}

.widget-user-about .info-list .social-share .social-icon a:hover {
    color: #615dfa;
}

.widget-user-about .info-list .social-share .social-icon a:first-child {
    padding-left: 0;
}




.profile-section ul {
    list-style: none;
  }
  
  .profile-section ul li::before {
    content: "• ";
    color: #1A59A0;
    font-weight: bold;
    display: inline-block; 
    width: 2em;
  }
  
  .profile-section ol li {
    list-style: decimal;
    margin-top: 10px;
    list-style-position: inside;
  }
  