p{
    font-size:16px;
    font-family: 'Poppins', sans-serif;
  }

.buttonStyle{
  position: relative;
  left: 45%;
  
  font-weight: bold;
  display:inline-block;
  padding:0.35em 1.2em;
  border:0.1em solid #131212;
  margin:1em 0.3em 0 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Poppins',sans-serif;
  background-color: #ffffff;

  color:#0f0e0e;
  text-align:center;
  transition: all 0.2s;
  border-radius: 5px;
  }
  .buttonStyle:hover{
  color:#f7eeee;
  border-radius: 5px;
  background-color:rgb(39, 65, 135);
  }

