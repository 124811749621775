@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
/* @import url(https://fonts.googleapis.com/css?family=Raleway:400,800); */
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

.wrapper {
  margin-top: 0.5rem;
}
*{
  font-family: "Poppins", sans-serif;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

figure.snip1197 {
  display: flex;
  margin: 10px;
  min-width: 400px;
  max-width: 100%;
  width: 100%;
  color: #333;
  text-align: left;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  padding: 1rem 0.8rem;
}
figure.snip1197 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
figure.snip1197 img {
  max-width: 40%;
  vertical-align: middle;
  border-radius: 10px;
}
figure.snip1197 figcaption {
  width: 60%;
  background-color: #ffffff;
  border-radius: 8px 0 0 8px;
}
figure.snip1197 blockquote {
  position: relative;
  padding: 25px 50px 25px 50px;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  line-height: 1.6em;
  text-align: justify;
}
figure.snip1197 blockquote:before,
figure.snip1197 blockquote:after {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 50px;
  opacity: 0.3;
  font-style: normal;
}
figure.snip1197 blockquote:before {
  top: 25px;
  left: 20px;
}
figure.snip1197 blockquote:after {
  content: "\201D";
  right: 20px;
  bottom: 0px;
}
figure.snip1197 .arrow {
  left: 100%;
  border-left: 0 solid transparent;
  border-right: 25px solid transparent;
  border-top: 25px solid #ffffff;
  margin: 0;
  position: absolute;
}
figure.snip1197 .author {
  position: absolute;
  top: 100%;
  width: 60%;
  padding: 10px 15px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}
figure.snip1197 .author h5 {
  opacity: 0.8;
  margin: 0;
  font-weight: 800;
}
figure.snip1197 .author h5 span {
  font-weight: 400;
  text-transform: none;
  padding-left: 5px;
}

.about {
  padding: 0rem 1rem 1rem;
  color: #274187;
}

@media (max-width: 762px) {
  .snip1197 {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  figure.snip1197 blockquote {
    padding: 0;
    padding-top: 10px;
    width: 100%;
    align-items: center;
  }

  figure.snip1197 img {
    max-width: 60%;
    vertical-align: middle;
    border-radius: 10px;
  }
}
