.drawer_style {
  background-color: "#274187";
  @media (max-width: 1100px) {
    margin-right: 0em;
  }
  @media (min-width: 1100px) and (max-width: 1300px) {
    margin-right: 4em;
  }
  @media (min-width: 1300px) and (max-width: 1800px) {
    margin-right: 4em;
  }
  @media (min-width: 1800px) and (max-width: 2100px) {
    margin-right: 5em;
  }
  @media (min-width: 2100px) {
    margin-right: 6em;
  }
}
