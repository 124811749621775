@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');


article{
    padding-top:100px;
    background-color: #B42E11;
    height: 100vh;
    width: 100%;
}

h1{
    color: white;
    font-weight: 900;
}

.author{
    font-weight: 600;
    color: #BCCFDA;
}