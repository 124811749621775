.outerContainer {
	width: 100%;
	border: 1px solid #000;
	margin-top: 7%;
}
#parentLink {
	margin-top: 0;
	/* background-color: #D50032; */
	background-color: #274187;
	padding: 10px 10px;
	color: #fff;
}
.innerContainer {
	margin: 5%;
	height: auto;
}

.childLink li {
	padding: 15px 8px;
	border-bottom: 1px solid gainsboro;
}

.childLink li:hover {
	/* background-color: yellow; */
	cursor: pointer;
	padding-left: 40px;
	transition: 0.3s;
}
.sidebarToggle {
	display: block;
}
@media (max-width: 991px) {
	.sidebarToggle {
		display: none;
	}
}
