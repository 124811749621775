.single_branch_div{
    width: 100%;
    border-radius: 5px;
    margin-bottom: 10px;
}
.branch_name{
    font-weight: bold;
    color: rgb(73, 107, 180);
    margin-top: '20px';
}
.branch_name:hover{
    color: rgb(24, 39, 75);
}
.branch_name::before{
    font-family: "Font Awesome 5 Free"; font-weight: 900; content: "\f007\ ";
    width: 100%;
}