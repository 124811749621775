@media (max-width: 991px) {
  .mobile_banner{
    display:block;
  }
  .desktop_banner{
    display: none;
  }
  .header-banner {
    /* background-image: url('../../../assets/images/others/newbuilding.jpg'); */
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .header-banner::before {
    content: '';
    width: 100%;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #header-text {
    font-size: 35px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    margin-top: 10%;
    text-shadow: 2px 2px #274187;
    word-wrap: break-word;
  }
}

@media (min-width: 991px) {
  .mobile_banner{
    display:none;
  }
  .desktop_banner{
    display: block;
  }
  .header-banner {
    /* background-image: url('../../../assets/images/others/newbuilding.jpg'); */
    height: 400px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }
  .header-banner::before {
    content: '';
    width: 100%;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  #header-text {
    font-size: 60px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    word-wrap: break-word;
    margin-top: 10%;
    text-shadow: 2px 2px #274187;
    /* text-transform: capitalize; */
  }
}
