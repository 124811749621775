.form-submit-button-notice{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      
}

.searched-submit-button-notice{
    border-radius: 10px;
    background-color: #445c9c;
    color: white;
    margin: 0 0 20px 10px;
    box-sizing: border-box;
    border: 2px solid #a8a8a8;
    border-radius: 10px;
    font-size: 16px; 
    padding: 10px 12px 10px 12px;
}