.sitemap-heading{
  font-size: 2.6em;
  margin: 30px 30px 20px 30px;
}

input[type="text"] {
  width: 130px;
  margin: 0 0 20px 30px;
  box-sizing: border-box;
  border: 2px solid #a8a8a8;
  border-radius: 10px;
  font-size: 16px;
  background-color: white;
  background-image: url("https://www.w3schools.com/css/searchicon.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 10px 20px 10px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}

input[type="text"]:focus {
  outline: none; /*to remove the focus outline in chrome */
  width: 250px;
}

.site-map-sec ul {
  padding-left: 45px;
  border-left: 2px solid #ddd;
  padding-top: 35px;
  padding-bottom: 25px;
  height: max-content;
}

.site-map-sec li {
  position: relative;
}

.sitemap-link span.grow {
  transition: all .2s ease-in-out;
  }

.sitemap-link span:hover {
    transform: scale(1.07); 
    }

.site-map-sec li:before {
  content: "";
  position: absolute;
  background: #ddd;
  width: 45px;
  height: 2px;
  top: 13px;
  left: -45px;
}