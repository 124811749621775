.wrapper{
  display:flex;
  align-items: center;
}

.card{
  max-width: 55rem;
  margin:10px 0;
  padding: 5px 10px;
  border: 3px solid rgb(39,65,135);
  border-radius: 20px !important;
}

.testimonial-content-wrapper{
  text-align: justify;
}

.testimonial-image{

  aspect-ratio: 1;
  max-width: 10rem;
  margin-right:1rem;

}

@media(max-width:762px){

  .testimonial-image{
    display:none !important;
  }

}